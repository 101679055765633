import { useActor } from "~/state/hooks";
import { createCopyWorkItemActor } from "./actor";
import { Team } from "@fscrypto/domain/team";
import { WorkItem } from "@fscrypto/domain/work-item";
import { CurrentUser } from "@fscrypto/domain";

export const useCopyWorkItem = () => {
  // You can either useActor and create the actor from the hook, or `useActorFromSystem` to get an existing actor
  const [state, ref] = useActor(() => createCopyWorkItemActor("copyWorkItem", {}));

  if (!state || !ref)
    return {
      isReady: false,
    } as const;

  return {
    openCopyItemModal: (payload: { workItemId: string; typename: WorkItem["typename"]; name: string }) =>
      ref.send({ type: "COPY_WORK_ITEM.SET_OPEN", payload }),
    closeModal: () => ref.send({ type: "COPY_WORK_ITEM.CLOSE" }),
    selectContext: (context: Team | CurrentUser) =>
      ref.send({ type: "COPY_WORK_ITEM.SELECT_CONTEXT", payload: { context } }),
    isOpen: state.matches("open"),
    isReady: true,
    teams: state.context.teams,
    workItems: state.context.workItems,
    isLoading: state.matches("open.fetchingRootWorkItems") || state.matches("open.fetchingCollectionWorkItems"),
    setSelectedCollectionId: (collectionId: string | null) =>
      ref.send({ type: "COPY_WORK_ITEM.SET_SELECTED_COLLECTION_ID", payload: { collectionId } }),
    selectedCollection: state.context.selectedCollection,
    currentCollection: state.context.currentCollection,
    setCurrentCollectionId: (collectionId: string | null) =>
      ref.send({ type: "COPY_WORK_ITEM.SET_CURRENT_COLLECTION_ID", payload: { collectionId } }),
    currentTypename: state.context.currentTypename,
    currentName: state.context.currentName,
    isInitialLoading: state.matches("open.fetchingRootWorkItems"),
    selectedContext: state.context.selectedContext,
    copyWorkItem: () => ref.send({ type: "COPY_WORK_ITEM.COPY_WORK_ITEM" }),
    isCopying: state.matches("open.copyingWorkItem"),
  };
};
