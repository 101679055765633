import { Client } from "@fscrypto/http";
import { $path } from "remix-routes";
import { workItem } from "@fscrypto/domain";

const client = new Client();

export const fetchWorkItem = async (id: string) => {
  const item = await client.get($path("/api/work-items/:id", { id: id }), workItem.schema);
  return item;
};
