import { WorkItem, WorkItemCopyToProfile } from "@fscrypto/domain/work-item";
import { ofType } from "@fscrypto/state-management";
import { type Observable, merge, map, switchMap, from, catchError, of, forkJoin } from "rxjs";
import { GET, POST } from "~/async/fetch";
import { partitionedAndSortedWorkItems } from "~/features/move-work-item/machines/move-work-item-machine";

// Input events, triggers epics
type FetchRootWorkItems = {
  type: "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS";
  payload: { parentId: string | null; profileId: string };
};
type FetchCollectionWorkItems = {
  type: "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS";
  payload: { parentId: string | null; profileId: string };
};

type CopyWorkItem = {
  type: "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM";
  payload: WorkItemCopyToProfile;
};

// Output events, sent to the machine
type FetchRootWorkItemsSuccess = { type: "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS_SUCCESS"; payload: WorkItem[] };
type FetchRootWorkItemsFailure = { type: "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS_FAILURE"; error: Error };

type FetchCollectionWorkItemsSuccess = {
  type: "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS_SUCCESS";
  payload: {
    collection: WorkItem;
    workItems: WorkItem[];
  };
};
type FetchCollectionWorkItemsFailure = {
  type: "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS_FAILURE";
  error: Error;
};

type CopyWorkItemSuccess = {
  type: "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM_SUCCESS";
  payload: { workItem: WorkItem; profileId: string };
};
type CopyWorkItemFailure = { type: "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM_FAILURE"; error: Error };

export type OutputEvent =
  | FetchRootWorkItemsSuccess
  | FetchRootWorkItemsFailure
  | FetchCollectionWorkItemsSuccess
  | FetchCollectionWorkItemsFailure
  | CopyWorkItemSuccess
  | CopyWorkItemFailure;

type InputEvent = FetchRootWorkItems | FetchCollectionWorkItems | CopyWorkItem;

/**
 * Merges multiple epic functions into a single Observable<OutputEvent>.
 * Merging these epics into one observable allows using a single subject for capturing all events.
 */
export const createEpic = (actions$: Observable<InputEvent>): Observable<OutputEvent> => {
  return merge(fetchWorkItemsEpic(actions$), fetchCollectionWorkItemsEpic(actions$), copyWorkItemEpic(actions$));
};

const fetchWorkItemsEpic = (action$: Observable<InputEvent>): Observable<OutputEvent> => {
  return action$.pipe(
    ofType("COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS"),
    switchMap(({ payload }) => {
      return from(fetchWorkItems(payload.profileId, payload.parentId)).pipe(
        map(
          (payload) =>
            ({
              type: "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS_SUCCESS",
              payload: partitionedAndSortedWorkItems(payload),
            }) as FetchRootWorkItemsSuccess,
        ),
        catchError((_) =>
          of({
            type: "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS_FAILURE",
            error: new Error("Unable to claim reward"),
          } as FetchRootWorkItemsFailure),
        ),
      );
    }),
  );
};

const fetchCollectionWorkItemsEpic = (action$: Observable<InputEvent>): Observable<OutputEvent> => {
  return action$.pipe(
    ofType("COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS"),
    switchMap(({ payload }) => {
      const { parentId, profileId } = payload;

      // Assuming `fetchWorkItem` returns a promise
      const collection = parentId ? from(fetchWorkItem(parentId)) : of(null);
      const workItems = from(fetchWorkItems(profileId, parentId));

      return forkJoin([workItems, collection]).pipe(
        map(
          ([workItems, collection]) =>
            ({
              type: "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS_SUCCESS",
              payload: {
                workItems: partitionedAndSortedWorkItems(workItems),
                collection,
              },
            }) as FetchCollectionWorkItemsSuccess,
        ),
        catchError((error) =>
          of({
            type: "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS_FAILURE",
            error: new Error("Failed to fetch collection work items."),
          } as FetchCollectionWorkItemsFailure),
        ),
      );
    }),
  );
};

const copyWorkItemEpic = (action$: Observable<InputEvent>): Observable<OutputEvent> => {
  return action$.pipe(
    ofType("COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM"),
    switchMap(({ payload }) => {
      return from(copyWorkItem(payload)).pipe(
        map(
          (workItem) =>
            ({
              type: "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM_SUCCESS",
              payload: { workItem, profileId: payload.profileId },
            }) as CopyWorkItemSuccess,
        ),
        catchError((_) =>
          of({
            type: "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM_FAILURE",
            error: new Error("Unable to copy work item"),
          } as CopyWorkItemFailure),
        ),
      );
    }),
  );
};

// ASYNC

// Add any async calls here
export const fetchWorkItems = async (profileId: string, parentId?: string | null) => {
  const endpoint = parentId
    ? `/api/work-items?parentId=${parentId}&profileId=${profileId}`
    : `/api/work-items?profileId=${profileId}`;
  const { items } = await GET<{ items: WorkItem[] }>(endpoint);
  return items;
};

export const fetchWorkItem = async (parentId?: string | null) => {
  const endpoint = `/api/work-items/${parentId}`;
  const { item } = await GET<{ item: WorkItem }>(endpoint);
  return item;
};

export const copyWorkItem = async (args: WorkItemCopyToProfile) => {
  const item = await POST<WorkItem, WorkItemCopyToProfile>(`/api/work-items/copy-to-profile`, args);
  return item;
};
