import { assign } from "xstate";
import { createActor } from "~/state/actor";
import { type SystemKey, createCopyWorkItemMachine, type Options } from "./machine";
import { createEpic } from "./epic";
import { actorSystem } from "~/state";
import invariant from "tiny-invariant";

export const createCopyWorkItemActor = createActor(
  { createMachine: createCopyWorkItemMachine, createEpic },
  ({ epic, eventBus }): Options => ({
    actions: {
      setInitialData: assign((ctx, e) => {
        return {
          workItemId: e.payload.workItemId,
          currentTypename: e.payload.typename,
          currentName: e.payload.name,
        };
      }),
      setContext: assign((ctx, e) => {
        return {
          selectedContext: e.payload.context,
          currentParentId: null,
        };
      }),
      resetData: assign((ctx, e) => {
        return {
          workItemId: null,
          teams: [],
          selectedContext: null,
          currentParentId: null,
          workItems: [],
          selectedCollection: null,
          currentCollectionId: null,
          currentCollection: null,
          currentTypename: null,
          currentName: null,
        };
      }),
      fetchRootWorkItemsEpic: (ctx) =>
        epic.send({
          type: "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS",
          payload: { parentId: ctx.currentParentId, profileId: ctx.selectedContext?.profileId ?? "" },
        }),
      fetchCollectionWorkItemsEpic: (ctx) =>
        epic.send({
          type: "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS",
          payload: { parentId: ctx.currentCollectionId, profileId: ctx.selectedContext?.profileId ?? "" },
        }),
      handleFetchRootWorkItemsSuccess: assign((ctx, e) => {
        return {
          workItems: e.payload,
        };
      }),
      setSelectedCollectionId: assign((ctx, e) => {
        return {
          selectedCollection:
            e.payload.collectionId === ctx.selectedCollection?.id
              ? null
              : ctx.workItems.find((item) => item.id === e.payload.collectionId),
        };
      }),
      setCurrentCollectionId: assign((ctx, e) => {
        return {
          currentCollectionId: e.payload.collectionId,
        };
      }),
      handleFetchCollectionWorkItemsSuccess: assign((ctx, e) => {
        return {
          currentCollection: e.payload.collection,
          workItems: e.payload.workItems,
          selectedCollection: null,
        };
      }),
      copyWorkItemEpic: (ctx) => {
        invariant(ctx.workItemId, "workItemId must be set");
        invariant(ctx.currentTypename, "currentTypename must be set");
        invariant(ctx.selectedContext, "selectedContext must be set");
        epic.send({
          type: "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM",
          payload: {
            id: ctx.workItemId,
            parentId: ctx.selectedCollection?.id ?? ctx.currentCollection?.id ?? null,
            typename: ctx.currentTypename as "query" | "dashboard",
            profileId: ctx.selectedContext?.profileId,
          },
        });
      },
      copyErrorToast: (ctx, e) => {
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: {
            title: "Error Copying Work Item",
            description: "Please try again",
            type: "error",
          },
        });
      },
      copySuccessToast: (ctx, e) => {
        eventBus.send({
          type: "TOAST.NOTIFY",
          notif: {
            title: "Work Item Successfully Copied",
            type: "success",
          },
        });
      },
    },
  }),
);

export type Actor = ReturnType<typeof createCopyWorkItemActor>;

export const getCopyWorkItemActor = (id: SystemKey) => {
  return actorSystem.get<Actor>(id);
};
