import { createMachine } from "xstate";
import type { OutputEvent as EpicEvent } from "./epic";
import { Team } from "@fscrypto/domain/team";
import { WorkItem } from "@fscrypto/domain/work-item";
import { User } from "@fscrypto/domain/user";
import { CurrentUser } from "@fscrypto/domain";

interface Context {
  workItemId: string | null;
  teams: Team[];
  selectedContext: User | Team | null;
  currentParentId: string | null;
  workItems: WorkItem[];
  selectedCollection: WorkItem | null;
  currentCollectionId: string | null;
  currentCollection: WorkItem | null;
  currentTypename: WorkItem["typename"] | null;
  currentName: string | null;
}

// A union of all events externally sent to the actor
type RefEvent =
  | { type: "COPY_WORK_ITEM.SET_OPEN"; payload: { workItemId: string; typename: WorkItem["typename"]; name: string } }
  | { type: "COPY_WORK_ITEM.CLOSE" }
  | { type: "COPY_WORK_ITEM.SELECT_CONTEXT"; payload: { context: Team | CurrentUser } }
  | { type: "COPY_WORK_ITEM.SET_SELECTED_COLLECTION_ID"; payload: { collectionId: string | null } }
  | { type: "COPY_WORK_ITEM.SET_CURRENT_COLLECTION_ID"; payload: { collectionId: string | null } }
  | { type: "COPY_WORK_ITEM.COPY_WORK_ITEM" };

type Event = RefEvent | EpicEvent;

// They key pattern that these actors can be referenced by
// If only one actor of this type will exist, you can remove the -${string}
export type SystemKey = `copyWorkItem`;

export const createCopyWorkItemMachine = (id: SystemKey) => {
  return createMachine({
    id,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    initial: "closed",
    context: {
      workItemId: null,
      teams: [],
      selectedContext: null,
      currentParentId: null,
      workItems: [],
      selectedCollection: null,
      currentCollectionId: null,
      currentCollection: null,
      currentTypename: null,
      currentName: null,
    },
    states: {
      open: {
        initial: "idle",
        states: {
          idle: {
            on: {
              "COPY_WORK_ITEM.SELECT_CONTEXT": {
                target: "fetchingRootWorkItems",
                actions: ["setContext"],
              },
              "COPY_WORK_ITEM.SET_SELECTED_COLLECTION_ID": {
                actions: "setSelectedCollectionId",
              },
              "COPY_WORK_ITEM.SET_CURRENT_COLLECTION_ID": {
                actions: "setCurrentCollectionId",
                target: "fetchingCollectionWorkItems",
              },
              "COPY_WORK_ITEM.COPY_WORK_ITEM": {
                target: "copyingWorkItem",
              },
            },
          },
          fetchingRootWorkItems: {
            entry: "fetchRootWorkItemsEpic",
            on: {
              "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS_SUCCESS": {
                target: "idle",
                actions: "handleFetchRootWorkItemsSuccess",
              },
              "COPY_WORK_ITEM.EPIC.FETCH_ROOT_WORK_ITEMS_FAILURE": {
                target: "idle",
              },
            },
          },
          fetchingCollectionWorkItems: {
            entry: "fetchCollectionWorkItemsEpic",
            on: {
              "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS_SUCCESS": {
                target: "idle",
                actions: "handleFetchCollectionWorkItemsSuccess",
              },
              "COPY_WORK_ITEM.EPIC.FETCH_COLLECTION_WORK_ITEMS_FAILURE": {
                target: "idle",
              },
            },
          },
          copyingWorkItem: {
            entry: "copyWorkItemEpic",
            on: {
              "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM_SUCCESS": {
                target: "#closed",
                actions: ["copySuccessToast", "resetData"],
              },
              "COPY_WORK_ITEM.EPIC.COPY_WORK_ITEM_FAILURE": {
                target: "idle",
                actions: "copyErrorToast",
              },
            },
          },
        },
        on: {
          "COPY_WORK_ITEM.CLOSE": {
            target: "closed",
            actions: "resetData",
          },
        },
      },
      closed: {
        id: "closed",
        on: {
          "COPY_WORK_ITEM.SET_OPEN": {
            target: "open",
            actions: "setInitialData",
          },
        },
      },
    },
  });
};

export type Options = Parameters<ReturnType<typeof createCopyWorkItemMachine>["withConfig"]>["0"];
